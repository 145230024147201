/* Font Import */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Universal Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

/* HTML Styles */
html {
  scroll-behavior: smooth;
}

/* Body Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif; /* Use the imported font */
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px; /* Increase width for better visibility */
}

::-webkit-scrollbar-track {
  background: #222a35;
}

::-webkit-scrollbar-thumb {
  background: #575c66;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #626970;
}
